<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false"
    ></loading>
    <b-alert v-model="flagNoAuth" variant="danger" dismissible>
      No posees permiso de administrador.
    </b-alert>  
    <!-- Fitlers -->
    <section>
      <!-- User Interface controls -->
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Ordenar por"
            label-for="sort-by-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sort-by-select"
                v-model="sortBy"
                :options="sortOptions"
                :aria-describedby="ariaDescribedby"
                class="w-75"
              >
                <template #first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>

              <b-form-select
                v-model="sortDesc"
                :disabled="!sortBy"
                :aria-describedby="ariaDescribedby"
                size="sm"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Orden inicial"
            label-for="initial-sort-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="initial-sort-select"
              v-model="sortDirection"
              :options="['asc', 'desc', 'last']"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filtro"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filterText"
                type="search"
                placeholder="Escribe para buscar"
                @keyup.enter="setFilterText"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filterText" @click="setFilterText">Buscar</b-button>
              </b-input-group-append>

              <b-input-group-append>
                <b-button :disabled="!filterText" @click="cleantFilterText">Limpiar</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            v-model="sortDirection"
            label="Filtrar por"
            description="Deja todo sin seleccionar para filtrar por todos los datos"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
              v-model="filterOn"
              :aria-describedby="ariaDescribedby"
              class="mt-1"
            >
              <b-form-checkbox value="clientName">Cliente</b-form-checkbox>
              <b-form-checkbox value="name">Mascota</b-form-checkbox>              
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>

        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="7" md="6" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </section>
    <!-- table -->
    <section>
        <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            stacked="md"
            show-empty
            small
            @filtered="onFiltered"
            :selectable="true"
            @row-clicked="rowClicked"
            >
            <template #cell(name)="row">
                {{ row.value }}
            </template>

            <template #cell(actions)="row">
                <b-button size="sm" @click="rowClicked(row.item, row.index, $event.target)" class="mr-1">Registrar pago</b-button>
                <b-button
                size="sm"
                @click="row.toggleDetails"
                >{{ row.detailsShowing ? 'Ocultar' : 'Ver' }} Detalle</b-button>
            </template>

            <template #row-details="row">
                <b-card>
                <b-row>                    
                    <b-col lg="12" md="12">
                        <div>
                            <strong>Id:</strong>
                            {{ row.item.id }}
                        </div>
                        <div>
                            <strong>Dirección:</strong>
                            {{ row.item.address }}
                        </div>
                        <div>
                            <strong>Celular:</strong>
                            {{ row.item.phone }}
                        </div>
                    </b-col>
                </b-row>
                </b-card>
            </template>
        </b-table>
    </section>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import _ from 'lodash'

export default {
  name: 'clients',
  components: {Loading},
  data () {
    return {
      loading: true,
      items: [],
      fields: [
        { key: "id", label: "id" },
        { key: "clientName", label: "Cliente", sortable: true, sortDirection: "desc"},       
        { key: "name", label: "Mascota", sortable: true, sortDirection: "desc" },
        { key: "breedName", label: "Raza", sortable: true },       
        { key: "actions", label: "Acciones" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 150,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterText: null,
      filterOn: [],
      flagNoAuth: false,

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  created () {    
    this.userProfile = JSON.parse(this.$store.getters.userProfile);
    if(this.userProfile.status != null) {
      this.getPets()
    } else {
      this.flagNoAuth = true;
      this.loading = false
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getPets () {
      let payload = {
        ciudades: _.get(this.userProfile, 'ciudades'),
      };
      let fetchPets = null
      // if (this.userProfile.acceso.id == 1) {
      //   fetchPets = this.$firebase.functions().httpsCallable('getPets');
      // } else {
      fetchPets = this.$firebase.functions().httpsCallable('getPetsByCity');
      // }
      fetchPets(payload).then((result) => {
        this.items = result.data.data
        this.items.forEach(item => {
            item["breedName"] = item.breed.nombre;
            item['clientName'] = item.client.nombre;
            item['petAddress'] = item.address;
            item['petPhone'] = item.phone;
            item['cities']= item.cities;
        });
        
      }).catch(error => {console.log(error)})
      .finally(()=> this.loading = false)
    },
    rowClicked: async function (item) {
      let clientId = item.client.id
      console.log(`ClientId: ${clientId}`);
      this.loading = true;
      let getPendingPayments = this.$firebase.functions().httpsCallable('getClientPendingPayments')
      getPendingPayments({clientId}).then(response => {
        this.loading = false;
        let pendingPayments = response.data
        this.$store.commit('defPaymentBy', item);
        this.$store.commit('defPendingPayments', pendingPayments);
        if(pendingPayments.length == 0){
          return this.$router.push({ path: '/financial/create_payment' })
        }  
        this.$router.push({ name: 'pending-payments' })
                              
      }).catch(error => {
        alert(error)
        return null
      })
    },
    setFilterText(){
      this.filter = this.filterText
    },
    cleantFilterText(){
      this.filter = null
      this.filterText = null
    }
   
  }

}
</script>

<style>

</style>